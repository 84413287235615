import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import PropTypes from 'prop-types'

const gotoDemo = ({ title = 'heading.gotoDemoHero', clickIds = [] }) => {
  const { t } = useTranslation()

  return (
    <section className="bg-blue-300 px-4 py-16">
      <div className="font-bold text-center text-white text-4xl">{t(title)}</div>
      <div className="flex flex-col lg:flex-row gap-3 items-center justify-center leading-7 mt-9">
        <Link id={clickIds[0]} to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
          {t('button.freeTrial.0')}
        </Link>
        <Link
          id={clickIds[1]}
          to="/lp/get-a-demo/"
          className="btn btn-bold bg-white inline-block btn-orange btn-ghost"
          style={{ width: 208 }}
        >
          {t('button.getDemo.0')}
        </Link>
      </div>
    </section>
  )
}
gotoDemo.propTypes = {
  title: PropTypes.string,
  clickIds: PropTypes.array
}

export default gotoDemo
